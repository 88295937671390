import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const r = document.getElementById('root');
r.style.height = '100vh';
const root = ReactDOM.createRoot(r);

root.render(<App />);
