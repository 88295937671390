/** @jsxImportSource @emotion/react */

import React from 'react';
import BaseDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import { STUDIO_HOST, USE_HTTP } from '../App';
import { Box, styled, Tooltip, useTheme } from '@mui/material';
import { css } from '@emotion/react/macro';
import {
  Add,
  AnimationOutlined,
  DisplaySettingsOutlined,
  Fullscreen,
  Info,
  TextSnippetOutlined,
} from '@mui/icons-material';
import { Knob } from './knob';
import { convertPercentageToValue, convertValueToPercentage } from './session';

export const drawerWidth = 220;

const DrawerHeader = styled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  minHeight: '48px !important',
  backgroundColor: 'black',
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  minHeight: '48px !important',
  backgroundColor: 'black',
}));

export default function Drawer({
  open,
  setOpen,
  setControlGroups,
  controlGroups,
  controlValues,
  setControlValue,
  toggleControlEditMode,
  isControlEditMode,
  toggleSketchEditMode,
  isSketchEditMode,
  toggleSketch,
  isSketch,
  setControlToEdit,
  newControl,
  mouseMoved,
  refresh,
  sketchRef,
  isHelpMode,
  isInfoMode,
  toggleInfoMode,
}) {
  const theme = useTheme();

  const addGroup = (afterIndex) => {
    const groups = [...controlGroups];
    groups.splice(afterIndex + 1, 0, { controls: [] });
    setControlGroups(groups);
  };

  const addControl = (group, index) => {
    const groups = [...controlGroups];
    const control = newControl();

    groups[index] = { ...groups[index], controls: [...group.controls, control] };
    setControlGroups(groups);
    editControl(control);
  };

  const editControl = (control) => {
    setControlToEdit({ ...control });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    refresh();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    refresh();
  };

  const AddGroup = ({ index }) => {
    return (
      <>
        {isControlEditMode && (
          <>
            <Divider />
            <IconButton
              title="Add Group"
              onClick={() => addGroup(index)}
              css={css`
                width: 100%;
                border-radius: 0;
                opacity: 0.2;
              `}
            >
              <Add />
            </IconButton>
          </>
        )}
      </>
    );
  };

  const AddControl = ({ group, index }) => {
    return (
      <>
        {isControlEditMode && (
          <ListItem
            title="Add Control"
            onClick={() => addControl(group, index)}
            css={css`
              width: 50%;
              padding: 0px;
              padding-left: 6px;
              &:hover {
                background-color: ${isControlEditMode ? '#333' : 'transparent'};
                cursor: pointer;
              }
              border-radius: 12px;
            `}
          >
            <Knob
              disabled={isControlEditMode}
              name="+"
              percent={0}
              bg="#333"
              fg="#999"
              autoFocus
              mouseSpeed={5}
              style={{
                margin: 0,
                fontFamily: 'Arial',
                color: '#999', // Sets font color of value and knob name
              }}
            />
          </ListItem>
        )}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MuiAppBar
        position="fixed"
        open={open}
        sx={{ height: '0' }}
        css={css`
          max-width: 64px;
          margin-top: -8px;
          opacity: ${mouseMoved ? 0.7 : 0};
          transition: opacity 3s ease-out;
        `}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flex: 1 }}></Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
      <BaseDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div
          css={css`
            padding-top: 48px;
            padding-bottom: 48px;
          `}
        >
          <AddGroup index={-1} />
          {controlGroups &&
            controlGroups.map((group, index) => {
              return (
                <React.Fragment key={index}>
                  <Divider />
                  <List
                    css={css`
                      display: flex;
                      flex-directin: row;
                      padding: 8px;
                      flex-wrap: wrap;
                      padding-left: 24px;
                    `}
                  >
                    {group.controls.map((control, index) => (
                      <ListItem
                        key={control.uuid}
                        css={css`
                          width: 50%;
                          padding: 0px;
                          padding-left: 6px;
                          &:hover {
                            background-color: ${isControlEditMode ? '#333' : 'transparent'};
                            cursor: pointer;
                          }
                          border-radius: 12px;
                        `}
                        onClick={() => {
                          isControlEditMode ? editControl(control) : void null;
                        }}
                        onMouseDown={(e) => {
                          control.type === 'boolean' || control.type === 'boolean_momentary'
                            ? setControlValue(control.id, !controlValues[control.id])
                            : void null;
                        }}
                        onMouseUp={(e) => {
                          control.type === 'boolean_momentary'
                            ? setTimeout(() => {
                                setControlValue(control.id, false);
                              }, 10)
                            : void null;
                        }}
                        onDoubleClick={(e) => {
                          isControlEditMode || control.type === 'boolean' || control.type === 'boolean_momentary'
                            ? void null
                            : setControlValue(
                                control.id,
                                e.shiftKey
                                  ? control.defaultValue
                                  : controlValues[control.id] >= (control.max - control.min) / 2
                                  ? control.min
                                  : control.max
                              );
                        }}
                      >
                        <Knob
                          disabled={
                            isControlEditMode || control.type === 'boolean' || control.type === 'boolean_momentary'
                          }
                          name={control.label}
                          type={control.type}
                          percent={convertValueToPercentage(controlValues[control.id] || control.min, control)}
                          onChange={(e) => setControlValue(control.id, e)}
                          bg="#333"
                          fg={control.color}
                          mouseSpeed={2}
                          unit={control.unit}
                          transform={(p) => convertPercentageToValue(p, control)}
                          style={{
                            margin: 0,
                            marginRight: -12,
                            fontFamily: 'Arial',
                            fontSize: 34,
                            color: '#666', // Sets font color of value and knob name
                          }}
                        />
                      </ListItem>
                    ))}
                    <AddControl group={group} index={index} />
                  </List>
                  <AddGroup group={group} index={index} />
                </React.Fragment>
              );
            })}

          <Divider />
        </div>

        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>

          <IconButton
            onClick={() => {
              sketchRef.current.requestFullscreen
                ? sketchRef.current.requestFullscreen()
                : sketchRef.current.webkitRequestFullscreen
                ? sketchRef.current.webkitRequestFullscreen()
                : sketchRef.current.msRequestFullscreen();
            }}
            title="Fullscreen"
          >
            <Fullscreen
              css={css`
                opacity: 0.5;
              `}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(`http${USE_HTTP ? '' : 's'}://${STUDIO_HOST}`, '_blank');
            }}
            title="Create a New Sketch"
          >
            <Add
              css={css`
                opacity: 0.5;
              `}
            />
          </IconButton>
        </DrawerHeader>
        <DrawerFooter>
          <IconButton onClick={toggleSketchEditMode} title="Toggle Code Editor">
            <TextSnippetOutlined
              css={css`
                opacity: ${isSketchEditMode ? 0.5 : 0.2};
              `}
            />
          </IconButton>
          <IconButton onClick={toggleSketch} title="Toggle Sketch">
            <AnimationOutlined
              css={css`
                opacity: ${isSketch ? 0.5 : 0.2};
              `}
            />
          </IconButton>
          <Tooltip arrow open={isHelpMode} title="Toggle Control Edit Mode">
            <IconButton onClick={toggleControlEditMode}>
              <DisplaySettingsOutlined
                css={css`
                  opacity: ${isControlEditMode ? 0.5 : 0.2};
                `}
              />
            </IconButton>
          </Tooltip>
          <IconButton onClick={toggleInfoMode} title="About &amp; Feedback">
            <Info
              css={css`
                opacity: ${isInfoMode ? 0.5 : 0.2};
              `}
            />
          </IconButton>
        </DrawerFooter>
      </BaseDrawer>
    </Box>
  );
}
