/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react/macro';
import { SketchPicker } from 'react-color';
import { Button, Popover } from '@mui/material';

export const ColorPicker = ({ onChange, value, id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div id={id}>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        css={css`
          background: ${value};
          height: 32px;
          width: 32px;
          min-width: 32px;
          margin-top: 12px;
          &:hover {
            background: ${value};
            opacity: 0.8;
          }
        `}
      ></Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SketchPicker color={value} onChange={onChange} />
      </Popover>
    </div>
  );
};
