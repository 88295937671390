/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { ColorPicker } from './colorPicker';

import { toast } from 'react-toastify';
import { Instagram, Sailing, Twitter, YouTube } from '@mui/icons-material';

export default function AboutDialog({ isInfoMode, closeDialog }) {
  return (
    <Dialog open={isInfoMode} onClose={closeDialog} maxWidth="sm">
      {isInfoMode && (
        <>
          <DialogTitle>Send us a message</DialogTitle>
          <DialogContent>
            <BottomNavigation showLabels>
              <BottomNavigationAction
                onClick={() => {
                  window.open('https://twitter.com/nightfall_stdio', 'social');
                }}
                label="Twitter"
                icon={<Twitter />}
              />
              <BottomNavigationAction
                onClick={() => {
                  window.open('https://www.instagram.com/_nightfall.studio_/', 'social');
                }}
                label="Instagram"
                icon={<Instagram />}
              />
            </BottomNavigation>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
