export const controlGroups = [
  {
    controls: [
      {
        unit: '°',
        label: 'Background',
        step: 1,
        defaultValue: 0,
        id: 'bg',
        min: 0,
        color: '#086640',
        uuid: 123,
        max: 360,
      },
      {
        color: '#daff00',
        label: 'Res',
        id: 'res',
        unit: '',
        min: 0,
        step: 1,
        max: 100,
        defaultValue: 70,
        uuid: '4da353d3-4a81-45c0-835f-06211d3029a1',
      },
    ],
    label: 'Size',
  },
  {
    controls: [
      {
        defaultValue: 300,
        label: 'Hue',
        max: 360,
        unit: '°',
        step: 1,
        color: '#416CEE',
        uuid: 1,
        id: 'hue',
        min: 0,
      },
      {
        id: 'sat',
        unit: '',
        max: 100,
        color: '#416CEE',
        uuid: 2,
        step: 1,
        min: 0,
        defaultValue: 100,
        label: 'Saturation',
      },
      {
        step: 0.01,
        label: 'Alpha',
        max: 1,
        defaultValue: 0.5,
        unit: '',
        uuid: 3,
        min: 0,
        color: '#416CEE',
        id: 'alpha',
      },
    ],
  },
  {
    controls: [
      {
        max: 5,
        label: 'Speed',
        step: 0.1,
        unit: '',
        id: 'speed',
        uuid: 4,
        color: '#1de90d',
        defaultValue: 0.7,
        min: 0.1,
      },
    ],
  },
  {
    controls: [
      {
        max: 80,
        defaultValue: 45,
        uuid: 5,
        unit: '',
        id: 'x',
        step: 0.1,
        color: '#41A6EE',
        label: 'X',
        min: 20,
      },
      {
        color: '#41A6EE',
        uuid: 6,
        label: 'Y',
        step: 1,
        defaultValue: 55,
        max: 1000,
        unit: '',
        min: 0,
        id: 'y',
      },
      {
        max: 50,
        color: '#e604ff',
        unit: '',
        min: 0,
        defaultValue: 19,
        uuid: 7,
        step: 0.1,
        id: 'r',
        label: 'Radius',
      },
    ],
  },
];

export const pre = `
(() => {
`;

export const sketchTemplate = `

function setup() {
  createCanvas(windowWidth, windowHeight);
  background(0);
  colorMode(HSB, 360, 100, 100, 1)
}

function draw() {

  background(II.bg)

  noStroke();
  fill(II.hue, II.sat, 100, II.alpha);
  _.range(0, II.res).forEach(i => {
    const x = map(i, 0, II.res, 0, windowWidth);
    const y = (windowHeight/2) + II.y * sin((x + frameCount * II.speed)/II.x);
    circle(
      x, y, II.r
    );
  });
}

`;

export const post = `
window.setup = setup;
window.draw = draw;

})();
`;
