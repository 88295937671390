/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import Session from './components/session';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { uniqueNamesGenerator, names, colors, animals } from 'unique-names-generator';
import { useCookies } from 'react-cookie';
import './App.css';

export const STUDIO_HOST = process.env.REACT_APP_NF_STUDIO_HOST || 'nightfall.studio';
export const CODE_HOST = process.env.REACT_APP_NF_CODE_HOST || 'nightfall.link';
export const USE_HTTP = process.env.REACT_APP_NF_USE_HTTP || false;

const nameConfig = {
  dictionaries: [colors, animals, names],
  separator: '-',
  style: 'lowercase',
};

const userNameConfig = {
  dictionaries: [colors, animals],
  separator: ' ',
  style: 'capital',
};

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const createAddress = () => uniqueNamesGenerator(nameConfig);

function App() {
  const [sessionId, setSessionId] = useState();
  const [isSketch, setIsSketch] = useState(null);
  const [cookies, setCookie] = useCookies(['username', 'existingUser']);

  const createUserName = () => {
    let un = cookies['username'];
    if (un) {
      return [un, !cookies['existingUser']];
    }
    un = uniqueNamesGenerator(userNameConfig);
    const exp = new Date();
    exp.setUTCFullYear(3000);
    setCookie('username', un, {
      domain: `.nightfall.studio`,
      path: '/',
      secure: !USE_HTTP,
      maxAge: 1000000000,
      expires: exp,
    });
    return [un, true];
  };

  const setExistingUser = () => {
    let existing = cookies['existingUser'];
    if (existing) {
      return;
    }
    const exp = new Date();
    exp.setUTCFullYear(3000);
    setCookie('existingUser', true, {
      domain: `.nightfall.studio`,
      path: '/',
      secure: !USE_HTTP,
      maxAge: 1000000000,
      expires: exp,
    });
  };

  useEffect(() => {
    const loc = window.location;
    let sid;
    if (loc.host === 'localhost:3000') {
      loc.replace(`http${USE_HTTP ? '' : 's'}://${STUDIO_HOST}`);
      return;
    }

    if (loc.host === STUDIO_HOST) {
      sid = createAddress();
      loc.replace(`http${USE_HTTP ? '' : 's'}://${sid}.${STUDIO_HOST}`);
      return;
    }

    if (loc.host.endsWith(CODE_HOST)) {
      sid = loc.host.replace(`.${CODE_HOST}`, '');
      if (sid.length < 10) {
        return;
      }
      setIsSketch(true);
    } else if (loc.host.endsWith(STUDIO_HOST) && loc.host !== STUDIO_HOST) {
      sid = loc.host.replace(`.${STUDIO_HOST}`, '');
      if (sid.length < 10) {
        loc.replace(`http${USE_HTTP ? '' : 's'}://${STUDIO_HOST}`);
        return;
      }
    }
    setSessionId(sid);
  }, []);

  const [userName, isNewUser] = createUserName();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {sessionId && (
          <Session
            sessionId={sessionId}
            isSketch={isSketch}
            user={userName}
            isNewUser={isNewUser}
            setExistingUser={setExistingUser}
          />
        )}
      </ThemeProvider>
    </Router>
  );
}

export default App;
