// based on https://github.com/TrendingTechnology/react-simple-knob
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Arc from './arc';

const viewBox = {
  height: 150,
  width: 250,
};

const angleRange = 270;
const angleOffset = 180;

export const Knob = ({
  type = 'number',
  disabled = false,
  percent = 0,
  onChange,
  bg = 'black',
  fg = 'white',
  transform = (p) => p,
  step,
  unit = '',
  name = '',
  style,
  mouseSpeed = 1,
}) => {
  const boxRef = useRef();
  const nameRef = useRef();
  const valueRef = useRef();

  const [drag, setDrag] = useState(false);
  const [angle, setAngle] = useState(0);
  const [text, setText] = useState({ x: 0, y: 0 });
  const [svgRatio, setSvgRatio] = useState(1);
  const [nameWidth, setNameWidth] = useState(20);
  const [lastVal, setLastVal] = useState();
  const [prevPageY, setPrevPageY] = useState();

  useEffect(() => {
    if (angle !== percent * angleRange) {
      setAngle(percent * angleRange);
    }
  }, [percent]);

  const handleDown = useCallback(() => {
    setDrag(true);
  }, [setDrag]);

  const handleUp = () => {
    setDrag(false);
    setPrevPageY(null);
  };

  const handleMove = useCallback(
    ({ pageY }) => {
      if (disabled) return;
      if (!drag) return;

      if (!prevPageY) {
        setPrevPageY(pageY);
        return;
      }
      const delta = (prevPageY - pageY) * mouseSpeed;

      const a = calcAngle(angle, delta, angleRange);
      if (angle / angleRange !== percent) {
        onChange(transform(angle / angleRange));
      }
      setAngle(a);
      setPrevPageY(pageY);
    },
    [drag, prevPageY, disabled]
  );

  const calcAngle = (angle, delta, angleRange) => {
    angle += delta;
    if (angle > angleRange) {
      angle -= angle - angleRange;
    } else if (angle < 0) {
      angle += Math.abs(angle);
    }
    return angle;
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleUp);
    // NOTE: We call this initially, to set the width and height values.
    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleUp);
    };
  }, [drag, prevPageY]);

  const calcSvgRatio = () => {
    const { width } = boxRef.current.getBoundingClientRect();
    setSvgRatio(width / viewBox.width);
  };

  useEffect(() => {
    fitText();
  }, [svgRatio]);

  const onWindowResize = () => {
    calcSvgRatio();
  };

  const fitText = () => {
    const rect = nameRef.current.getBoundingClientRect();
    setNameWidth(rect.width / svgRatio);
  };

  const percentage = angle / angleRange;
  const { width, height } = viewBox;
  const outerCircle = {
    arcWidth: 10,
    radius: 40,
  };
  const font = {
    marginBottom: 5,
    size: (style && style.fontSize) || 40,
    family: (style && style.fontFamily) || 'Arial',
  };

  return (
    <svg ref={boxRef} onMouseDown={handleDown} style={{ ...style, width }} viewBox={`0 0 ${width} ${height}`}>
      <text
        ref={nameRef}
        style={{
          fill: style && style.color,
          fontFamily: font.family,
          pointerEvents: 'none',
          cursor: 'pointer',
          userSelect: 'none',
        }}
        x="0"
        y={font.size}
        fontSize={font.size}
      >
        {name}
      </text>
      <circle
        fill={percentage > 0 ? fg : bg}
        r={type === 'boolean' || type === 'boolean_momentary' ? 30 : 25}
        cx={outerCircle.arcWidth + outerCircle.radius}
        cy={50 + font.size + font.marginBottom}
      />
      {type !== 'boolean' && type !== 'boolean_momentary' && (
        <Arc
          percentage={percentage}
          angleOffset={angleOffset}
          angleRange={angleRange}
          arcWidth={outerCircle.arcWidth}
          radius={outerCircle.radius}
          center={50}
          background={bg}
          color={fg}
          style={{
            transform: `translateY(${font.size + font.marginBottom}px)`,
          }}
        />
      )}
      <text
        ref={valueRef}
        style={{
          fill: style && style.color,
          fontFamily: (style && style.fontFamily) || 'Arial',
          pointerEvents: 'none',
          cursor: 'pointer',
          userSelect: 'none',
        }}
        x={80}
        y={outerCircle.arcWidth + 2 * outerCircle.radius + font.size}
        fontSize={font.size}
      >
        {type !== 'boolean' && type !== 'boolean_momentary' && `${transform(percentage)} ${unit}`}
      </text>
    </svg>
  );
};
