/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { ColorPicker } from './colorPicker';

import { toast } from 'react-toastify';

export default function ControlDialog({
  controlToEdit,
  closeDialog,
  midiMappings,
  setControlToEdit,
  updateControl,
  deleteControl,
  controlsByUUID,
}) {
  return (
    <Dialog open={!!controlToEdit} onClose={closeDialog} maxWidth="sm">
      {controlToEdit && (
        <>
          <DialogTitle>Edit Control</DialogTitle>
          <DialogContent>
            <FormControl margin="dense" label="Type" fullWidth variant="standard">
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                id="type"
                aria-describedby="type"
                value={controlToEdit.type || 'number'}
                onChange={(e) => {
                  setControlToEdit({ ...controlToEdit, type: e.target.value });
                }}
              >
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="boolean">Boolean</MenuItem>
                <MenuItem value="boolean_momentary">Boolean (Momentary)</MenuItem>
                {/* <MenuItem value="modulator">Modulator (not yet fully implemented)</MenuItem> */}
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              id="label"
              label="Control Label"
              type="string"
              fullWidth
              variant="standard"
              value={controlToEdit.label}
              onChange={(e) =>
                e.target.value.length <= 9 && setControlToEdit({ ...controlToEdit, label: e.target.value })
              }
            />

            <TextField
              autoFocus
              margin="dense"
              id="id"
              label="Variable Name"
              type="string"
              fullWidth
              variant="standard"
              value={controlToEdit.id}
              onChange={(e) =>
                e.target.value.length <= 12 && setControlToEdit({ ...controlToEdit, id: e.target.value })
              }
            />

            <>
              {(controlToEdit.type || 'number') === 'number' && (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="defaultValue"
                    label="Default Value"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.defaultValue}
                    onChange={(e) => setControlToEdit({ ...controlToEdit, defaultValue: e.target.value })}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="min"
                    label="Minimum Value"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.min}
                    onChange={(e) => setControlToEdit({ ...controlToEdit, min: e.target.value })}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="max"
                    label="Maximum Value"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.max}
                    onChange={(e) => setControlToEdit({ ...controlToEdit, max: e.target.value })}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="step"
                    label="Step"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.step}
                    onChange={(e) => setControlToEdit({ ...controlToEdit, step: e.target.value })}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="unit"
                    label="Unit"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.unit}
                    onChange={(e) => setControlToEdit({ ...controlToEdit, unit: e.target.value })}
                  />
                </>
              )}
            </>

            <>
              {controlToEdit.type === 'modulator' && (
                <>
                  <FormControl margin="dense" label="Type" fullWidth variant="standard">
                    <InputLabel htmlFor="algorithm">Algorithm</InputLabel>
                    <Select
                      id="algorithm"
                      aria-describedby="algorithm"
                      value={controlToEdit.algorithm || 'sinLFO'}
                      onChange={(e) => {
                        setControlToEdit({ ...controlToEdit, algorithm: e.target.value });
                      }}
                    >
                      <MenuItem value="sinLFO">Sine Wave LFO</MenuItem>
                      <MenuItem value="triangleLFO">Triangle Wave LFO</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="Period (in ms)"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.period || 1000}
                    onChange={(e) =>
                      e.target.value.length <= 12 && setControlToEdit({ ...controlToEdit, period: e.target.value })
                    }
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="Minimum (percentage)"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.min}
                    onChange={(e) =>
                      e.target.value >= 0 &&
                      e.target.value <= 100 &&
                      setControlToEdit({ ...controlToEdit, min: e.target.value })
                    }
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="Maximum (percentage)"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={controlToEdit.max}
                    onChange={(e) =>
                      e.target.value >= 0 &&
                      e.target.value <= 100 &&
                      setControlToEdit({ ...controlToEdit, max: e.target.value })
                    }
                  />

                  <FormControl margin="dense" label="Type" fullWidth variant="standard">
                    <InputLabel htmlFor="targets">Target Controls</InputLabel>
                    <Select
                      id="targets"
                      aria-describedby="targets"
                      value={controlToEdit.targets || []}
                      multiple
                      onChange={(e) => {
                        setControlToEdit({ ...controlToEdit, targets: e.target.value });
                      }}
                    >
                      {controlsByUUID &&
                        Object.values(controlsByUUID).map((c) => {
                          return (
                            <MenuItem key={c.uuid} value={c.uuid}>
                              {c.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </>
              )}
            </>

            <TextField
              disabled
              autoFocus
              margin="dense"
              id="unit"
              label="Midi"
              type="string"
              fullWidth
              variant="standard"
              value={
                (Object.entries(midiMappings).find(([k, v]) => v === controlToEdit.uuid) || ['Touch control to map'])[0]
              }
            />

            <FormControl margin="dense" label="Type" fullWidth variant="standard">
              <ColorPicker
                id="color"
                value={controlToEdit.color}
                onChange={(v) => setControlToEdit({ ...controlToEdit, color: v.hex })}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button
              onClick={() => {
                if (confirm(`Delete control ${controlToEdit.label}?`)) {
                  deleteControl(controlToEdit);
                  closeDialog();
                }
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                const invalid = Object.entries(controlToEdit)
                  .filter(([k, v]) => k !== 'unit')
                  .find(([k, v]) => v === null || `${v}`.length === 0);
                if (invalid) {
                  toast(`All fields are required`);
                  return;
                }
                if (controlToEdit.type === 'boolean' || controlToEdit.type === 'boolean_momentary') {
                  controlToEdit.min = false;
                  controlToEdit.max = true;
                  controlToEdit.step = 1;
                  controlToEdit.defaultValue = false;
                }
                updateControl(controlToEdit);
                closeDialog();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
