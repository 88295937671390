import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { doc, setDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';
import firebaseCompat from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDi8l_rUdwJtdddBjfytG0lxeiH24O4hKQ',
  authDomain: 'nightfall-studio.firebaseapp.com',
  projectId: 'nightfall-studio',
  storageBucket: 'nightfall-studio.appspot.com',
  messagingSenderId: '1041655244532',
  appId: '1:1041655244532:web:31e38e3cb1a076627d20a1',
  databaseURL: 'https://nightfall-studio-default-rtdb.firebaseio.com',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const compatApp = firebaseCompat.initializeApp(firebaseConfig);
const auth = firebaseCompat.auth();

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const getEditorRef = (sessionId) => {
  return compatApp.database().ref(sessionId);
};

export const authenticateAnonymously = () => {
  return signInAnonymously(getAuth(app));
};

export const streamSession = (id, observer) => {
  const itemsColRef = doc(db, 'sessions', id);
  return onSnapshot(itemsColRef, observer);
};

export const streamControlValues = (id, observer) => {
  const itemsColRef = doc(db, 'controlValues', id);
  return onSnapshot(itemsColRef, observer);
};

export const updateSession = async (id, session) => {
  const sessionColRef = doc(db, 'sessions', id);
  return setDoc(sessionColRef, session, { merge: true });
};

export const updateControlValue = async (sessionId, controlId, value) => {
  if (controlId) {
    const sessionColRef = doc(db, 'controlValues', sessionId);
    return setDoc(sessionColRef, { [controlId]: value }, { merge: true });
  }
};
