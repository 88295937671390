/** @jsxImportSource @emotion/react */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { css } from '@emotion/react/macro';
import { fromMonaco } from '@filtered/firepad';
import { getEditorRef } from '../services/Firestore';
import { styled } from '@mui/material/styles';
import * as JZZ from 'jzz';
import Drawer, { drawerWidth } from './drawer';
import { useNightfallSession } from '../services/NightfallStudio';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Editor from '@monaco-editor/react';
import moment from 'moment';
import ControlDialog from './controlDialog';
import { Button, Link, Tooltip } from '@mui/material';
import { Help, LinkOutlined, SaveOutlined } from '@mui/icons-material';
import AboutDialog from './aboutDialog';

export const convertPercentageToValue = (percentage, control) => {
  const valueRange = control.max - control.min;

  // Apply percentage to outputRange
  let newValue = valueRange * percentage + control.min;

  // Snap to closest step
  const step = control.step;
  newValue = Math.round(newValue / step) * step;

  // Ensure we haven't snapped outside of range...snap to min/max again
  newValue = Math.min(Math.max(newValue, control.min), control.max);

  const ret = Math.trunc(newValue * 100, 2) / 100;

  if (control.type === 'boolean' || control.type === 'boolean_momentary') {
    return !!ret;
  }

  return ret;
};

export const convertValueToPercentage = (value, control) => {
  // Snap to min/max
  if (value === undefined) {
    return 0;
  }

  if (control.type === 'boolean' || control.type === 'boolean_momentary') {
    return !!value ? 1 : 0;
  }

  const newValue = Math.min(Math.max(value, control.min), control.max);

  const valueRange = control.max - control.min;
  let percentage = (newValue - control.min) / valueRange;

  // Ensure we haven't snapped outside of range...snap to min/max again
  percentage = Math.min(Math.max(percentage, 0), 1.0);

  return percentage;
};

const defaultIsControlEditMode = localStorage.getItem('nf_isControlEditMode') === 'true';
const defaultIsSketchEditMode = localStorage.getItem('nf_isSketchEditMode') === 'true';
const defaultIsSketch = localStorage.getItem('nf_isSketch') !== 'false';
const defaultDrawerOpen = localStorage.getItem('nf_isDrawerOpen') !== 'false';
const defaultMidiMappings = JSON.parse(localStorage.getItem('nf_midi') || '{}');

export default function Session({ sessionId, user, isNewUser, setExistingUser }) {
  const iframeRef = useRef();
  const sketchRef = useRef();
  const logRef = useRef();
  const {
    controlGroups,
    controlsByUUID,
    setControlGroups,
    controlValues,
    setControlValue,
    updateControl,
    deleteControl,
    newControl,
    sketch,
    setSketch,
    hash,
    log,
    refresh,
  } = useNightfallSession({ iframeRef, sessionId });
  const [drawerOpen, setDrawerOpen] = useState(defaultDrawerOpen);
  const [isControlEditMode, setIsControlEditMode] = useState(defaultIsControlEditMode);
  const [isSketchEditMode, setIsSketchEditMode] = useState(defaultIsSketchEditMode);
  const [isSketch, setIsSketch] = useState(defaultIsSketch);
  const [controlToEdit, setControlToEdit] = useState();
  const [theme, setTheme] = useState('vs-dark');
  const [sketchSource, setSketchSource] = useState('');
  const [mouseMoved, setMouseMoved] = useState(false);
  const [unMouseMoved, setUnMouseMoved] = useState();
  const [monaco, setMonaco] = useState();
  const [firepad, setFirepad] = useState();
  const [midiMessage, setMidiMessage] = useState();
  const [midiMappings, setMidiMappings] = useState(defaultMidiMappings);
  const [isHelpMode, setIsHelpMode] = useState(false);
  const [isInfoMode, setIsInfoMode] = useState(false);

  useEffect(() => {
    if (monaco && sessionId) {
      const fp = fromMonaco(getEditorRef(sessionId), monaco);
      fp.setUserName(user);
      fp.setUserColor('#6d8ff3');
      setFirepad(fp);
      return () => {
        fp.dispose();
      };
    }
  }, [monaco, sessionId]);

  useEffect(() => {
    if (logRef && logRef.current) {
      logRef.current.scrollTo(0, logRef.current.scrollHeight + 1000);
    }
  }, [logRef, log]);

  useEffect(() => {
    try {
      JZZ.requestMIDIAccess().then(() => {
        JZZ()
          .or('Cannot start MIDI engine!')
          .openMidiIn()
          .or('No MIDI Device found')
          .connect((msg) => {
            if (msg.getChannel() !== undefined) {
              setMidiMessage(msg);
            }
          });
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (midiMappings) {
      localStorage.setItem('nf_midi', JSON.stringify(midiMappings));
    }
  }, [midiMappings]);

  useEffect(() => {
    if (midiMessage) {
      if (isControlEditMode && controlToEdit) {
        setMidiMappings({
          ...midiMappings,
          [`${midiMessage[0]}.${midiMessage[1]}`]: controlToEdit.uuid,
        });
      } else if (!isControlEditMode) {
        const controlUUID = midiMappings[`${midiMessage[0]}.${midiMessage[1]}`];
        if (controlUUID) {
          const control = controlsByUUID[controlUUID];
          if (control) {
            setControlValue(control.id, convertPercentageToValue(midiMessage[2] / 127.0, control));
          }
        }
      }
    }
  }, [midiMessage]);

  const onMouseMove = useCallback(() => {
    const un = unMouseMoved;
    setUnMouseMoved(undefined);
    if (un) {
      clearTimeout(un);
    }
    setMouseMoved(true);
    setUnMouseMoved(
      setTimeout(() => {
        setUnMouseMoved(undefined);
        setMouseMoved(false);
      }, 3000)
    );
  }, [unMouseMoved]);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);

    return () => window.removeEventListener('mousemove', onMouseMove);
  }, [onMouseMove]);

  useEffect(() => {
    setSketchSource(sketch);
  }, [sketch]);

  const toggleControlEditMode = () => {
    if (isControlEditMode) {
      setControlGroups(controlGroups.filter((group) => group.controls && group.controls.length > 0));
    }

    setIsControlEditMode(!isControlEditMode);
  };

  const toggleInfoMode = () => {
    setIsInfoMode(!isInfoMode);
  };

  useEffect(() => {
    localStorage.setItem('nf_isControlEditMode', isControlEditMode);
  }, [isControlEditMode]);

  useEffect(() => {
    localStorage.setItem('nf_isSketchEditMode', isSketchEditMode);
  }, [isSketchEditMode]);

  useEffect(() => {
    localStorage.setItem('nf_isSketch', isSketch);
  }, [isSketch]);

  useEffect(() => {
    localStorage.setItem('nf_isDrawerOpen', drawerOpen);
  }, [drawerOpen]);

  const toggleSketchEditMode = () => {
    setIsSketchEditMode(!isSketchEditMode);
  };

  const toggleSketch = () => {
    setIsSketch(!isSketch);
  };

  const closeDialog = () => {
    setControlToEdit(undefined);
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      setSketch(sketchSource);
    }
  };

  return (
    <>
      {isHelpMode && (
        <div
          id="helpMask"
          onMouseDown={(e) => {
            if (e.target.id !== 'p5ref') {
              setExistingUser(true);
              setIsHelpMode(false);
            }
          }}
          css={css`
            background: rgba(17, 17, 17, 0.3);
            height: 100vh;
            width: 100vw;
            position: fixed;
            z-index: 1400;
          `}
        >
          <div
            css={css`
              flex-grow: 1;
              text-align: right;
              font-size: 1.2rem;
              padding-right: 16px;
              padding-top: 6px;
              position: relative;
            `}
          >
            {isHelpMode && (
              <a
                css={css`
                  color: #7c32c0;
                  position: absolute;
                  z-index: 2000;
                  right: ${drawerOpen ? drawerWidth + 100 : 160}px;
                `}
                id="p5ref"
                href="https://p5js.org/reference/"
                target="_blank"
              >
                <LinkOutlined /> P5.js Documentation
              </a>
            )}
          </div>
        </div>
      )}
      <ToastContainer position="top-center" />
      <Main
        open={drawerOpen}
        css={css`
          position: relative;
          overflow: hidden;
        `}
      >
        <div
          ref={sketchRef}
          css={css`
            height: 100%;
            overflow: hidden;
          `}
        >
          <iframe
            src="about:blank"
            ref={iframeRef}
            key={hash}
            title="sketch"
            sandbox="allow-scripts allow-same-origin"
            css={css`
              visibility: ${isSketch ? 'inherit' : 'hidden'};
              width: 100%;
              height: 100%;
              border: none;
              opacity: 0;
              transition: opacity 500ms ease-in-out;
              overflow: hidden;
            `}
          />
        </div>
        {sketch && isSketchEditMode && (
          <div
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 100;
              padding-top: 48px;
              .monaco-editor,
              .monaco-editor-background,
              .monaco-editor .inputarea.ime-input {
                background: transparent;
              }
            `}
            onKeyDown={handleKeyDown}
          >
            {!isHelpMode && (
              <Editor
                height="calc(100% - 120px)"
                theme={theme}
                loading=""
                defaultLanguage="javascript"
                options={{ minimap: { enabled: false }, wordWrap: true }}
                saveViewState={true}
                defaultValue={sketchSource}
                onChange={(newValue) => {
                  setSketchSource(newValue);
                }}
                onMount={(e, m) => {
                  setMonaco(e);
                }}
                css={css`
                  & * {
                    user-select: auto;
                  }
                `}
              />
            )}
            {isHelpMode && (
              <Editor
                height="calc(100% - 120px)"
                theme={theme}
                loading=""
                defaultLanguage="text"
                options={{ minimap: { enabled: false }, wordWrap: true }}
                saveViewState={true}
                defaultValue={`
              



  Edit your p5.js code here.  
  
  If you share the url of this window with others, you will see each other's edits and changes to controls in real time!

  The current value of controls can be accessed in your code using \`II.foo\`, where foo is the variable name of the control.

  Switch to control edit mode using the button on the bottom right then click on a control to view or change the variable name from a control, or click the "+" signs to add more controls or groups of controls.


              
              `}
              />
            )}
            <div
              css={css`
                position: fixed;
                left: 0;
                top: 0;
                height: 52px;
                padding-bottom: 8px;
                padding-left: 0;
                display: flex;
                flex-direction: row;
                width: calc(100% - ${drawerOpen ? drawerWidth : 60}px);
              `}
            >
              <Tooltip arrow open={isHelpMode} title="Save &amp; run your code changes (or press ctrl / ⌘-S )">
                <Button
                  disabled={sketchSource === sketch}
                  onClick={() => {
                    setSketch(sketchSource);
                  }}
                  variant="contained"
                  css={css`
                    background: #222;
                    color: #aaa;
                    border-radius: 1px;
                  `}
                >
                  <SaveOutlined />
                </Button>
              </Tooltip>
              <div
                css={css`
                  flex-grow: 1;
                  text-align: right;
                  font-size: 1.2rem;
                  padding-right: 16px;
                  padding-top: 6px;
                  position: relative;
                `}
              ></div>
              <Tooltip arrow open={isHelpMode} title="Show these tips again. Click anywhere to hide them.">
                <Button
                  onClick={() => {
                    setIsHelpMode(!isHelpMode);
                  }}
                  variant="contained"
                  css={css`
                    background: #222;
                    color: #aaa;
                    border-radius: 1px;
                    margin-left: 2px;
                  `}
                >
                  <Help />
                </Button>
              </Tooltip>
            </div>
            <Tooltip
              arrow
              open={isHelpMode}
              title="See errors and messages from your sketch. Use console.log('hi'); to log here"
            >
              <div
                ref={logRef}
                css={css`
                  height: 120px;
                  color: #999;
                  border-top: 1px solid #333;
                  overflow: auto;
                  font-size: 14px;
                `}
              >
                {log.map((l, index) => (
                  <div
                    key={index}
                    css={css`
                      padding-left: 8px;
                      border-bottom: 1px solid #222;
                      white-space: pre-wrap;
                      &:last-child {
                        border-bottom: none;
                      }
                      &:nth-of-type(even) {
                        background-color: rgba(17, 17, 17, 0.5);
                      }
                      &:nth-of-type(odd) {
                        background-color: rgba(48, 48, 48, 0.5);
                      }
                    `}
                  >
                    {moment(l.time).format('HH:mm:ss')} : {l.message}
                  </div>
                ))}
              </div>
            </Tooltip>
          </div>
        )}
      </Main>
      <Drawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        setControlGroups={setControlGroups}
        controlGroups={controlGroups}
        setControlValue={setControlValue}
        controlValues={controlValues}
        toggleControlEditMode={toggleControlEditMode}
        isControlEditMode={isControlEditMode}
        toggleSketchEditMode={toggleSketchEditMode}
        isSketchEditMode={isSketchEditMode}
        toggleSketch={toggleSketch}
        isSketch={isSketch}
        setControlToEdit={setControlToEdit}
        newControl={newControl}
        mouseMoved={mouseMoved}
        refresh={refresh}
        sketchRef={sketchRef}
        isHelpMode={isHelpMode}
        isInfoMode={isInfoMode}
        toggleInfoMode={toggleInfoMode}
      />

      <ControlDialog
        controlToEdit={controlToEdit}
        closeDialog={closeDialog}
        midiMappings={midiMappings}
        setControlToEdit={setControlToEdit}
        updateControl={updateControl}
        deleteControl={deleteControl}
        controlsByUUID={controlsByUUID}
      />

      <AboutDialog isInfoMode={isInfoMode} closeDialog={() => setIsInfoMode(false)} />
    </>
  );
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  marginTop: 0,
  backgroundColor: 'black',
  height: '100vh',
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
  color: 'white',
  padding: 0,
}));
