(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@operational-transformation/state-machine"), require("mitt"));
	else if(typeof define === 'function' && define.amd)
		define(["@operational-transformation/state-machine", "mitt"], factory);
	else if(typeof exports === 'object')
		exports["@operational-transformation/plaintext-editor"] = factory(require("@operational-transformation/state-machine"), require("mitt"));
	else
		root["@operational-transformation/plaintext-editor"] = factory(root["@operational-transformation/state-machine"], root["mitt"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__746__, __WEBPACK_EXTERNAL_MODULE__265__) {
return 