import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { CODE_HOST, STUDIO_HOST, USE_HTTP } from '../App';
import { post, pre, sketchTemplate, controlGroups as defaultGroups } from '../sketchTemplate';
import {
  authenticateAnonymously,
  streamSession,
  streamControlValues,
  updateControlValue,
  updateSession,
} from './Firestore';
import _ from 'lodash';
import { Easing, Tween, update as updateTween } from '@tweenjs/tween.js';

const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

const UID_KEY = 'nightfallUserId';
function animate(time) {
  requestAnimationFrame(animate);
  updateTween(time);
}
requestAnimationFrame(animate);

let tweens = [];

export const useNightfallSession = (props) => {
  const [sessionId, setSessionId] = useState(props.sessionId);
  const [iframeRef, setIframeRef] = useState(props.iframeRef);
  const [sketch, setSketch] = useState();
  const [ready, setReady] = useState(false);
  const [loadedSession, setLoadedSession] = useState(false);
  const [loadedControlValues, setLoadedControlValues] = useState(false);
  const [loadedSketch, setLoadedSketch] = useState(false);
  const [controlValues, setControlValues] = useState({});
  const [controlValueUpdaters, setControlValueUpdaters] = useState({});
  const [controlGroups, setControlGroups] = useState([]);
  const [modulators, setModulators] = useState([]);
  const [controlsByUUID, setControlsByUUID] = useState({});
  const [messageQueue, setMessageQueue] = useState([]);

  const [hash, setHash] = useState();
  const [log, setLog] = useState([]);

  useEffect(() => {
    if (sessionId) {
      authenticateAnonymously().then((userCred) => {
        localStorage.setItem(UID_KEY, userCred.user.uid);
      });

      const unsubscribe1 = streamSession(sessionId, {
        next: (docSnapshot) => {
          const session = docSnapshot.data();
          if (session) {
            if (session.controlGroups && session.sessionId && session.code) {
              setControlGroups(session.controlGroups);
              if (session.code !== sketch) {
                setReady(false);
                setLoadedSketch(false);
                setSketch(session.code);
                setHash(hashCode(session.code));
                //if (!loadedSession) {
                setTimeout(() => setLoadedSession(true), 200);
                //}
              }
            }
          } else {
            updateSession(sessionId, {
              sessionId,
              code: sketchTemplate,
              controlGroups: [...defaultGroups],
            });
          }
        },
        error: (error) => console.error(error),
      });

      const unsubscribe2 = streamControlValues(sessionId, {
        next: (docSnapshot) => {
          if (!docSnapshot.metadata.hasPendingWrites) {
            // Not local
            const values = docSnapshot.data();
            if (values) {
              window.setControlValueFactory()(values);
            }
          }
        },
        error: (error) => console.error(error),
      });

      return () => {
        unsubscribe1();
        unsubscribe2();
      };
    }
  }, [sessionId, sketch]);

  const setControlValueFactory = useCallback(
    () => (values) => {
      const newVals = { ...controlValues };
      Object.entries(values).forEach(([k, v]) => {
        const oldVal = controlValues[k];
        if (oldVal === undefined) {
          newVals[k] = v;
        } else if (oldVal !== v) {
          const old = { [k]: oldVal };
          const t = new Tween(old)
            .to({ [k]: v }, 250)
            .onUpdate(() => {
              window.setControlValuesFactory()(old);
            })
            .onComplete(() => {
              tweens.shift(1);
            });

          if (tweens.length > 0) {
            tweens[tweens.length - 1].onComplete(() => {
              tweens.shift(1);
              t.start();
            });
            tweens.push(t);
          } else {
            tweens.push(t);
            t.start();
          }
        }
      });
      setControlValues(newVals);
      if (!loadedControlValues) {
        setTimeout(() => setLoadedControlValues(true), 100);
      }
    },
    [controlValues]
  );

  window.setControlValueFactory = setControlValueFactory;

  window.setControlValuesFactory = useCallback(
    () => (val) => {
      setControlValues({ ...controlValues, ...val });
    },
    [controlValues, setControlValueFactory]
  );

  useEffect(() => {
    window.addEventListener('resize', refresh);

    return () => {
      window.removeEventListener('resize', refresh);
    };
  }, [hash]);

  const updateControlGroups = (newGroups) => {
    updateSession(sessionId, { controlGroups: newGroups });
  };

  const updateSketch = useCallback(
    (v) => {
      if (v !== sketch) {
        updateSession(sessionId, { code: v });
      }
    },
    [sketch]
  );

  const refresh = useCallback(
    _.debounce(() => {
      iframeRef.current.style.opacity = 0;
      setTimeout(() => {
        setReady(false);
        setLoadedSketch(false);
        setHash(hash + 1);
      }, 100);
    }, 500),
    [hash, setHash]
  );

  const processMessage = (msg) => {
    switch (msg.type) {
      case 'sketch.ready':
        setReady(true);
        sendMessage({ type: 'studio.ready' });
        if (controlValues && sketch && controlGroups) {
          sendControlValues();
          sendSketch();
        }
        iframeRef.current.style.opacity = 1;
        break;
      case 'sketch.log':
        if (msg.value && msg.value.length) {
          setLog([...log, { time: new Date().getTime(), message: msg.value.join(' ') }].slice(-30));
        }
      default:
    }
  };

  useEffect(() => {
    const uuidIndex = {};
    (controlGroups || []).flatMap((g) => g.controls).forEach((c) => (uuidIndex[c.uuid] = c));
    setControlsByUUID(uuidIndex);
  }, [controlGroups]);

  // useEffect(() => {
  //   if(controlGroups) {
  //     setModulators(controlGroups.flatMap(g => g.controls)
  //       .filter(c => c.type === 'modulator')
  //       .map(c => {
  //         switch(c.algorithm) {
  //           case 'sinLFO':
  //             return new SinLFO({min: c.min, max: c.max, targets: c.targets, period: c.period, start : controlValues[c.id]}, setControlValuePercentage);
  //             break;
  //           default:
  //         }
  //       }));
  //   }
  // }, [controlGroups, controlValues]);

  useEffect(() => {
    if (loadedSketch && ready && controlValues) {
      sendControlValues();
    }
  }, [controlValues, ready, loadedSketch]);

  const newControl = () => {
    return {
      uuid: uuid(),
      id: '',
      label: '',
      defaultValue: 50,
      min: 0,
      max: 100,
      step: 1,
      unit: '',
      color: '#3f49b0',
    };
  };

  const setControlValue = (id, val) => {
    setControlValues({ ...controlValues, [id]: val });
    let up = controlValueUpdaters[id];
    if (up) {
      up(val);
    } else {
      up = _.throttle((v) => updateControlValue(sessionId, id, v), 500);
      setControlValueUpdaters({ ...controlValueUpdaters, [id]: up });
      up(val);
    }
  };

  useEffect(() => {
    if (controlsByUUID) {
      const updaters = { ...controlValueUpdaters };
      Object.values(controlsByUUID).forEach((control) => {
        updaters[control.id] = _.throttle((v) => updateControlValue(sessionId, control.id, v), 500);
      });
      setControlValueUpdaters(updaters);
    }
  }, [controlsByUUID]);

  const setControlValuePercentage = (uuid, p) => {
    const control = controlsByUUID[uuid];
    if (control) {
      const range = control.max - control.min;
      const v = p * range + control.min;
      setControlValue(control.id, v);
    }
  };

  const cleanNumber = (val) => {
    val = 1.0 * val;
    return isNaN(val) ? 0 : val;
  };

  const cleanControl = (control) => {
    if (control.type === 'boolean' || control.type === 'boolean_momentary') {
      return control;
    }
    control.defaultValue = cleanNumber(control.defaultValue);
    control.min = cleanNumber(control.min);
    control.max = cleanNumber(control.max);
    control.step = cleanNumber(control.step);
    return control;
  };

  const deleteControl = (control) => {
    const newGroups = controlGroups
      .map((group) => {
        return {
          ...group,
          controls: group.controls.filter((c) => c.uuid !== control.uuid),
        };
      })
      .filter((group) => group.controls.length > 0);

    updateControlGroups(newGroups);
  };

  const updateControl = (control) => {
    control = cleanControl(control);
    const updaters = {};
    const newGroups = controlGroups.map((group) => {
      const oldControl = group.controls.find((c) => c.uuid === control.uuid);
      if (oldControl) {
        return {
          ...group,
          controls: group.controls.map((c) => {
            if (c.uuid === control.uuid) {
              const oldVal = controlValues[control.id];
              if (oldVal === undefined || oldVal > control.max || oldVal < control.min) {
                setControlValue(control.id, control.defaultValue);
              }
              return control;
            } else {
              return c;
            }
          }),
        };
      } else {
        return group;
      }
    });
    updateControlGroups(newGroups);
  };

  useEffect(() => {
    if (controlGroups && sessionId) {
      const values = { ...controlValues };
      controlGroups.forEach((group) => {
        group.controls.forEach((control) => {
          const oldVal = controlValues[control.id];
          if (oldVal === undefined || oldVal > control.max || oldVal < control.min) {
            values[control.id] = control.defaultValue;
            updateControlValue(sessionId, control.id, control.defaultValue);
          }
        });
      });
      setControlValues(values);
      if (!loadedControlValues) {
        setTimeout(() => setLoadedControlValues(true), 100);
      }
    }
  }, [controlGroups, sessionId]);

  const receive = useCallback(
    (e) => {
      let data;
      try {
        data = JSON.parse(e.data);
        //console.log('Studio received message', data);
        processMessage(data);
      } catch (err) {
        //ignore
        return;
      }
    },
    [sketch, controlGroups, controlValues, ready, loadedSketch]
  );

  useEffect(() => {
    if (sessionId && iframeRef && iframeRef.current && sketch && hash) {
      window.addEventListener('message', receive);
      if (loadedSession && loadedControlValues && !loadedSketch) {
        // iframeRef.current.contentWindow.location = `/sketch.html?${encodeURIComponent(
        //   `https://localhost:3000`
        // )}`;
        iframeRef.current.style.opacity = 0;
        iframeRef.current.contentWindow.location = `http${
          USE_HTTP ? '' : 's'
        }://${sessionId}.${CODE_HOST}/sketch.html?${encodeURIComponent(
          `http${USE_HTTP ? '' : 's'}://${sessionId}.${STUDIO_HOST}`
        )}`;
        setLoadedSketch(true);
      }

      return () => {
        window.removeEventListener('message', receive);
      };
    }
  }, [iframeRef, sessionId, receive, sketch, loadedSketch, loadedSession, loadedControlValues, hash]);

  const sendMessage = useCallback(
    (msg) => {
      iframeRef.current.contentWindow.postMessage(
        JSON.stringify(msg),
        `http${USE_HTTP ? '' : 's'}://${sessionId}.${CODE_HOST}`
      );
    },
    [loadedSketch, iframeRef]
  );

  const sendControlValues = () => {
    sendMessage({ type: 'studio.values', value: controlValues });
  };

  const sendSketch = () => {
    sendMessage({ type: 'studio.src', value: `${pre}\n\n${sketch}${post}` });
  };

  return {
    controlValues,
    controlGroups,
    controlsByUUID,
    setControlValue,
    setControlGroups: updateControlGroups,
    updateControl,
    deleteControl,
    newControl,
    sketch,
    setSketch: updateSketch,
    hash,
    log,
    refresh,
  };
};
